<template>
  <v-data-table
    :loading="isLoading"
    :headers="headers"
    :items="tableData"
    item-key="name"
    class="elevation-1"
    style="margin-top:20px"
  >
    <template v-slot:item.enroll_number="{ item }">
      <template v-if="!item.editing">

      <v-chip v-if="item.enroll_number"
        class="ma-2"
        color="success"
        outlined
      >{{item.enroll_number}}</v-chip>
      <v-chip v-else
        class="ma-2"
        color="red"
        outlined
      > - </v-chip>
      </template>
      <v-text-field v-else
            v-model="item.enroll_number"
            class="d-input-no-foot"
            placeholder="Mã chấm công"
            outlined
            dense
          ></v-text-field>
    </template>
    <template v-slot:item.user_role="{ item }">
      <v-chip
        class="ma-2"
        :color="item.user_role|roleStatusColor"
        label
        text-color="white"
      >{{item.user_role|roleStatusText}}</v-chip>
    </template>
    <template v-slot:item.user_status="{ item }">
      <v-chip
        class="ma-2"
        :color="item.user_status|userStatusColor"
        label
        text-color="white"
      >{{item.user_status|userStatusText}}</v-chip>
    </template>
    <template v-slot:item.acc_ac="{ item }">
      <v-btn-toggle dense color="#033">
        <v-btn v-if="!item.editing" small color="warning" class="text-white" @click="item.editing = true">
          <v-icon class="text-white" left>
        mdi-pencil
      </v-icon> Sửa
        </v-btn>
        <v-btn :loading="item.loading"
      :disabled="item.loading" v-else small color="success" class="text-white" @click="saveChange(item)">
          <v-icon class="text-white" left>
        fas fa-save
      </v-icon> Lưu
        </v-btn>
        <router-link :to="'/admin/users/'+item.id">
          <v-btn small color="blue darken-1" class="text-white">
            Xem chi tiết
          </v-btn>
        </router-link>
      </v-btn-toggle>
    </template>
  </v-data-table>
</template>
<script>
import { updateUserStore } from '@/api/user-store.js'
export default {
  data() {
    return {
      newUserDialogShow: false,
      headers: [
        { text: "Tên đăng nhập", value: "user_login" },
        { text: "Tên", value: "user_fullname" },
        { text: "SĐT", value: "user_phone" },
        { text: "Mã chấm công", value: "enroll_number" },
        { text: "Quyền", value: "user_role", align: "center" },
        { text: "Trạng thái", value: "user_status", align: "center" },
        { text: "Actions", value: "acc_ac" }
      ],

    };
  },
  props:{
    tableData:{
        type:Array,
    },
    isLoading:{
        type:Boolean,
    }
  },
  methods: {
    async saveChange (userStore) {
      userStore.loading = true
      try {
        await updateUserStore(userStore.user_store_id, {
          enroll_number: userStore.enroll_number
        })
        userStore.editing = false
      } catch (e) {

      }
      userStore.loading = false
    }
  }

};
</script>
<template>
<div>


  <v-card :loading="isLoading">
    <div>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="headline">Thêm cửa hàng mới</v-list-item-title>
          <v-list-item-subtitle>Thông tin cửa hàng</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <div style="padding:20px">
        <v-text-field dense v-model="storeInfo.name" label="Tên cửa hàng" outlined></v-text-field>
        <v-text-field dense v-model="storeInfo.address" label="Địa chỉ" outlined></v-text-field>
        <v-text-field dense v-model="storeInfo.phone" label="SĐT" outlined></v-text-field>
        <v-row>
          <v-col cols="6">
            <v-text-field type="number" v-model="storeInfo.lat" dense label="Lat" outlined></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field type="number" v-model="storeInfo.lng" dense label="Lng" outlined></v-text-field>
          </v-col>
        </v-row>
      </div>
      <v-divider class="m-0"></v-divider>
      <div>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="headline">Máy chấm công</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div class="px-4">
          <v-text-field dense v-model="storeInfo.tracker_token" label="Token" outlined>
            <template v-slot:append-outer>
              <v-btn
              depressed
              color="primary"
              style="margin-top: -6px;"
              @click="genTrackerToken"
            >
              <i class="fas fa-sync-alt" />
            </v-btn>
            </template>
          </v-text-field>
        </div>
      </div>
      <v-divider></v-divider>
      <v-card-actions style="padding:0 20px 20px 20px">
        <v-spacer></v-spacer>
        <v-btn
          v-if="isNewPage"
          :loading="isLoading"
          color="primary"
          @click="newClick()"
        >Thêm cửa hàng</v-btn>
        <template v-else>
          <v-btn :loading="isLoading" @click="deleteClick()" color="error">Xóa</v-btn>
          <v-btn :loading="isLoading"  color="primary">Sửa thông tin</v-btn>
        </template>
      </v-card-actions>
    </div>
  </v-card>
    <v-card style="margin-top:20px">
        <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="headline">Danh sách nhân viên tại cửa hàng</v-list-item-title>

        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
        <UsersTable :isLoading="usersTableIsLoading" :tableData="users"></UsersTable>
    </v-card>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { createNewStore, getStoreInfo,deleteStore,getUsersOfStore } from "@/api/stores";
import UsersTable from '../user/components/UsersTable'
export default {
    components:{
        UsersTable
    },
  data() {
    return {
      isLoading: false,
      usersTableIsLoading:false,
      users:[],
      storeInfo: {
        name: "",
        address: "",
        phone: null,
        lat: null,
        lng: null,
        tracker_token: null
      }
    };
  },
  methods: {
    genTrackerToken () {
      this.storeInfo.tracker_token = Math.random().toString(36).substring(2);
    },
    deleteClick(){
      this.$confirm("Bạn có chắc muốn xóa cửa hàng, bạn sẽ không thể hoàn tác",
       { color: "warning", title: "Warning" }
      ).then(res=>{
        if(res){
            this.isLoading = true;
            deleteStore({id:this.storeInfo.id}).then(()=>{
                this.isLoading = false;
                this.$router.push("/admin/stores/");
            }).catch(()=>{
                this.isLoading = false
            })
        }
    });
    },
    newClick() {
      this.isLoading = true;
      createNewStore(this.storeInfo)
        .then(res => {
          this.isLoading = false;
          this.$router.push("/admin/stores/" + res.data.id);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    fetchData() {
      this.isLoading = true;
      getStoreInfo({ id: this.storeInfo.id }).then(res => {
        let info = res.data;
        this.isLoading = false;
        this.storeInfo.name = info.store_name;
        this.storeInfo.address = info.store_address;
        this.storeInfo.phone = info.store_phone;
        this.storeInfo.lat = info.store_lat;
        this.storeInfo.lng = info.store_lng;
        this.storeInfo.tracker_token = info.tracker_token;
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Cửa hàng", route: "/admin/stores" },
          { title: info.store_name, route: info.id }
        ]);
      });
      this.usersTableIsLoading = true;
      getUsersOfStore({ id: this.storeInfo.id }).then(res=>{
          this.usersTableIsLoading = false;
          res.data.map(e => {
            e.editing = false
            e.loading = false
          })
          this.users = res.data;
      })
    }
  },
  created() {
    if (this.isNewPage) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Cửa hàng", route: "/admin/stores" },
        { title: "Tạo mới", route: "new" }
      ]);
    } else {
      this.storeInfo.id = this.$route.params.id;
      this.fetchData();
    }
  },
  computed: {
    isNewPage() {
      if (this.$route.path.includes("new")) {
        return true;
      }
      return false;
    }
  }
};
</script>